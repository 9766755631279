import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { PaymentStatusModelComponent } from '../../payment/payment-status-model/payment-status-model.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
// 
@Component({
  selector: 'app-expense-detail',
  templateUrl: './expense-detail.component.html'
})
export class ExpenseDetailComponent implements OnInit {
  constructor(public dialog : MatDialog, public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { 
    
  }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'expense_id':id},'expense/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['result']; 
        console.log(this.data);
        this.data.travel_entitlement = [];
        this.data.food = [];
        this.data.hotel = [];
        this.data.local_conveyance = [];
        this.data.misc = [];
        this.data.expenseDataDetail.map(r=>{
          if(r.expense_type.toLowerCase().includes('travel entitlement'))this.data.travel_entitlement.push(r)
          if(r.expense_type.toLowerCase().includes('food'))this.data.food.push(r)
          if(r.expense_type.toLowerCase().includes('hotel'))this.data.hotel.push(r)
          if(r.expense_type.toLowerCase().includes('local conveyance'))this.data.local_conveyance.push(r)
          if(r.expense_type.toLowerCase().includes('misc'))this.data.misc.push(r)
        })
        console.log(this.data);

      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  
  
  
}
