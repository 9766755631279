import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { AddBonusModelComponent } from '../../add-bonus-points/add-bonus.component';
import { MatDialog } from '@angular/material';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { FollowupModelComponent } from 'src/app/followup-model/followup-model.component';
import { LeadAddRemarkModelComponent } from '../add-remark/add-remark.component';
import { StatusModelComponent } from '../../status-model/status-model.component';
import { LeadStatusModelComponent } from '../lead-status-model/lead-status-model.component';

@Component({
  selector: 'app-dealer-top-header',
  templateUrl: './dealer-top-header.component.html'
})
export class DealerTopHeaderComponent implements OnInit {
  status:boolean = false
  constructor(public customEvent:CustomEventsService,public dialog:MatDialog,private render: Renderer2,private _location: Location,public activatedroute:ActivatedRoute,public db:DatabaseService) { 
    customEvent.subscribe('refresh-lead-detail',resp=>{
      this.getDetail(this.activatedroute.snapshot.params.id)
    })
  }

  backClicked() {
    this._location.back();
  }
  toggleNav() 
  {
    this.status = !this.status;
    if(this.status) {
      this.render.addClass(document.body, '');
    }
    else {
      this.render.removeClass(document.body, '');
    }
  }
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'customer_id':id},'dis_network/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data']; 
        console.log(this.data);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }


  addFollowup(): void {
    const dialogRef = this.dialog.open(FollowupModelComponent, {
      width: '360px',
      autoFocus: false,
      data: this.data
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  addRemark(): void {
    const dialogRef = this.dialog.open(LeadAddRemarkModelComponent, {
      width: '360px',
      autoFocus: false,
      data: this.data
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
  openStatusDialog(): void {
    this.data.basicDetail.encrypt_id =  this.activatedroute.snapshot.params.id
    const dialogRef = this.dialog.open(LeadStatusModelComponent, {
      width: '400px',
      autoFocus: false,
      data: this.data.basicDetail
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.customEvent.publish('refresh-lead-detail',{});
    });
  }
}
