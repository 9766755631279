import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { Location } from '@angular/common';
import { VendorContactEditModelComponent } from '../vendor-contact-edit-model/vendor-contact-edit-model.component';
import { MatDialog } from '@angular/material';
import { VendorInfoEditModelComponent } from '../vendor-info-edit-model/vendor-info-edit-model.component';
import swal from 'sweetalert';
// import { VendorInfoEditModelComponent } from '../vendor-info-edit-model/vendor-info-edit-model.component';

@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  // styleUrls: ['./dr-detail.component.scss']
})
export class VendorDetailComponent implements OnInit {

  constructor(public dialog: MatDialog, public activatedroute: ActivatedRoute, private _location: Location, public db: DatabaseService) { }

  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  data: any = {};
  getDetail(id) {
    this.db.presentLoader();

    this.db.postReq({ 'vendor_id': id }, 'vendor/detail').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.data = resp['data'];
        console.log(this.data);

      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errHandler(err);
    })

  }
  backClicked() {
    this._location.back();
  }

  openDistribution(): void {
    const dialogRef = this.dialog.open(VendorInfoEditModelComponent, {
      width: '600px',
      autoFocus: false,
      data: this.data.basicDetail
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDetail(this.activatedroute.snapshot.params.id)
    });

  }

  openEditContact(i): void {
    const dialogRef = this.dialog.open(VendorContactEditModelComponent, {
      width: '700px',
      autoFocus: false,
      data: this.data.contactDetail[i]
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDetail(this.activatedroute.snapshot.params.id)
    });
  }
  addContact(): void {
    const dialogRef = this.dialog.open(VendorContactEditModelComponent, {
      width: '700px',
      autoFocus: false,
      data: { vendor_id: this.activatedroute.snapshot.params.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDetail(this.activatedroute.snapshot.params.id)
    });
  }
  contactDelete(data) {
    swal({
      title: "Are you sure?",
      text: "You want delete this contact detail!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.db.presentLoader();
          this.db.postReq({ vendor_id: data.vendor_id, contact_id: data.id }, 'dis_network/delete_contact_detail').subscribe(resp => {
            if (resp['status'] == 'success') {
              swal("Deleted!", {
                icon: "success",
              });
              this.getDetail(this.activatedroute.snapshot.params.id)

            } else {
              if (resp['message'] == 'Invalid token') {
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'], resp['message'])
            }
          })

        } else {
          swal("Your data is safe!");
        }
      });
  }
}
