import { Component, Inject, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, Validators } from "@angular/forms";
import { DatabaseService } from "src/app/_services/database.service";
@Component({
  selector: "app-bulk-status-update-model",
  templateUrl: "./bulk-status-update-model.component.html",
})
export class BulkStatusUpdateModelComponent implements OnInit {
  constructor(
    public db: DatabaseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _location: Location
  ) {}

  ngOnInit() {
    this.formsInit(this.data);
    // console.log("this.data",this.data);
    this.getData();
  }

  form: FormGroup;
  formsInit(data: any) {
    this.form = this.db.formBuilder.group({
      status: [data.status ? data.status : null, [Validators.required]],
      status_remark: [data.remark ? data.remark : null],
    });
    console.log(this.form.value);
  }
  backClicked() {
    this._location.back();
  }

  status_list: any = [];
  reqData: any = {};
  getData() {
    let reqData = {};
    this.db.presentLoader();
    this.db.postReq(reqData, "dis_network/status_list").subscribe(
      (resp) => {
        this.db.dismissLoader();
        if (resp["status"] == "success") {
          this.status_list = resp["result"].data;
        } else {
          if (resp["message"] == "Invalid token") {
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp["status"], resp["message"]);
        }
      },
      (err) => {
        this.db.errHandler(err);
        this.db.errDismissLoader();
      }
    );
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    // let reqData:any = {};
    let reqData = this.form.value;
    reqData = this.form.value;
    reqData.dr_ids = this.data;
    console.log("reqData", reqData);
    // return;
    if (reqData.status == "Rejected") {
      if (!reqData.status_remark) {
        this.db.presentAlert("Alert", "Please provide Remark!");
        
        return;
      }
    }
    this.db.presentLoader();
    this.db.postReq(reqData, "dis_network/bulk_change_status").subscribe(
      (resp) => {
        this.db.dismissLoader();
        if (resp["status"] == "success") {
          this.db.successAlert(resp["status"], resp["message"]);
          this.db.matdialog.closeAll();
        } else {
          if (resp["message"] == "Invalid token") {
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp["status"], resp["message"]);
        }
      },
      (err) => {
        this.db.errHandler(err);
        this.db.errDismissLoader();
      }
    );
  }
}
